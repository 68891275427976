import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material"
import { Check, Close } from "@mui/icons-material"

function PartinfoDialog({ open, handleClose }) {
  const [partNumbersInput, setPartNumbersInput] = useState("")
  const [setNumbersInput, setSetNumbersInput] = useState("")
  const [tableData, setTableData] = useState([])
  const [smbData, setSmbData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleCloseDialog = () => {
    handleClose()
  }

  const handleParsePartNumbers = async () => {
    const partNumbersArray = partNumbersInput.split(/[;,\t]/).map((partNumber) => partNumber.trim().replaceAll(" ", ""))

    try {
      setLoading(true)

      const response = await fetch(`https://api.playmodok.nl/api/playmobil/parts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ partNumbers: partNumbersArray }),
      })

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`)
      }

      const newData = await response.json()
      setTableData(newData)
    } catch (error) {
      console.error(`Fout bij het ophalen van gegevens: ${error.message}`)
      setTableData([])
    } finally {
      setLoading(false)
    }
  }

  const handleParseSetNumbers = async () => {
    const setNumbersArray = setNumbersInput.split(/[;,\t]/).map((setNumber) => setNumber.trim().replaceAll(" ", ""))

    try {
      setLoading(true)

      const response = await fetch(`https://api.playmodok.nl/api/smb/sets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ setNumbers: setNumbersArray }),
      })

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`)
      }

      const newData = await response.json()
      setSmbData(newData)
    } catch (error) {
      console.error(`Fout bij het ophalen van gegevens: ${error.message}`)
      setSmbData([])
    } finally {
      setLoading(false)
    }
  }

  const handleRefresh = () => {
    setPartNumbersInput("")
    setSetNumbersInput("")
    setTableData([])
    setSmbData([])
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
      <DialogTitle>
        Extra informatie voor
        <Button variant="contained" color="primary" onClick={handleParsePartNumbers} style={{ marginLeft: "10px" }}>
          Onderdelen...
        </Button>
        <Button variant="contained" color="primary" onClick={handleParseSetNumbers} style={{ marginLeft: "10px" }}>
          Sets...
        </Button>
        <Button variant="contained" color="primary" onClick={handleRefresh} style={{ marginLeft: "10px" }}>
          Verversen
        </Button>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", gap: "16px" }}>
          <TextField
            variant="filled"
            label="Voer partnummers in (gescheiden door ;)"
            fullWidth
            multiline
            value={partNumbersInput}
            onChange={(e) => setPartNumbersInput(e.target.value)}
          />
          <TextField
            variant="filled"
            label="Voer setnummers in (gescheiden door ;)"
            fullWidth
            multiline
            value={setNumbersInput}
            onChange={(e) => setSetNumbersInput(e.target.value)}
          />
        </div>
        {loading ? (
          <CircularProgress style={{ marginTop: "10px" }} />
        ) : (
          <>
            {tableData.length > 0 && (
              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table size="small" stickyHeader>
                  {/* Tabelkop */}
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Afbeelding
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Onderdeel
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Naam
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Prijs
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Beschikbaar
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Tabelinhoud */}
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row.image !== "Niet beschikbaar" ? <img src={row.image} height="50" alt="Afbeelding" /> : "Niet beschikbaar"}
                        </TableCell>
                        <TableCell>{row.partNumber}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.available ? <Check style={{ color: "green" }} /> : <Close style={{ color: "red" }} />}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {/* Tabel voor smbData */}
            {smbData.length > 0 && (
              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table size="small" stickyHeader>
                  {/* Tabelkop */}
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Setnummer
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Beschrijving
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Tabelinhoud */}
                  <TableBody>
                    {smbData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.setNumber}</TableCell>
                        <TableCell>{row.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PartinfoDialog
