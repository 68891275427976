import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  AppBar,
  Toolbar,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Card,
  Container,
  IconButton,
  useMediaQuery,
  TextField,
} from "@mui/material"
import {
  Refresh as RefreshIcon,
  CloudDownloadOutlined as CloudDownloadIcon,
  CloudUploadOutlined as CloudUploadIcon,
  Email as EmailIcon,
  EmailOutlined as EmailOutlinedIcon,
  LocalShipping as LocalShippingIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
  UnfoldMore as UnfoldMoreIcon,
  TipsAndUpdatesOutlined as TipsAndUpdatesIcon,
  Clear as ClearIcon,
  Euro as EuroIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import "./App.css"
import Order from "./components/Order"
import ExportDialog from "./components/Export"
import ImportDialog from "./components/Import"
import PartinfoDialog from "./components/Partinfo"
import ReportDialog from "./components/Report"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  appBar: {
    height: "64px",
  },
  content: {
    position: "relative",
    flex: 1,
    overflow: "hidden",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "80px",
  },
  tableContainer: {
    width: "100%",
    overflow: "hidden",
  },
  table: {
    tableLayout: "auto",
    width: "100%",
  },
  tableCell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}))

function App() {
  const classes = useStyles()
  const [orders, setOrders] = useState(null)
  const [loading, setLoading] = useState(false)
  const [allRowsExpanded, setAllRowsExpanded] = useState(false)
  const [showBriefpost, setShowBriefpost] = useState(false)
  const [showThuisbezorgen, setShowThuisbezorgen] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [importDialogOpen, setImportDialogOpen] = useState(false)
  const [partinfoDialogOpen, setPartinfoDialogOpen] = useState(false)
  const [reportDialogOpen, setReportDialogOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const isMobile = useMediaQuery("(max-width: 600px)")

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        setShowBriefpost(false)
        setShowThuisbezorgen(false)

        const ordersResponse = await axios.get("https://www.playmodok.nl/wp-json/wc/v3/orders?status=processing&per_page=70", {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        })

        const ordersData = ordersResponse.data.map((order) => {
          const lastName = order.shipping.last_name.toLowerCase()
          const lastNameFrequency = ordersResponse.data.filter((o) => o.shipping.last_name.toLowerCase() === lastName).length
          const backgroundColor = lastNameFrequency > 1 ? "#f2f2f2" : "inherit"
          return {
            ...order,
            backgroundColor,
          }
        })

        setOrders(ordersData)
      } catch (error) {
        console.error("Error:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleDone = async (orderId) => {
    try {
      setLoading(true)

      const response = await axios.put(
        `https://www.playmodok.nl/wp-json/wc/v3/orders/${orderId}`,
        {
          status: "completed",
        },
        {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }
      )

      console.log("Order gesloten:", response.data)
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId))
    } catch (error) {
      console.error("Fout bij het sluiten van de order:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleDoneMain = async (orderIds) => {
    try {
      setLoading(true)

      const updates = orderIds.map((orderId) => ({
        id: orderId,
        status: "completed",
      }))

      const response = await axios.post(
        "https://www.playmodok.nl/wp-json/wc/v3/orders/batch",
        { update: updates },
        {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }
      )

      console.log("Orders gesloten:", response.data)
      console.log(updates)
      setOrders((prevOrders) => prevOrders.filter((order) => !orderIds.includes(order.id)))
    } catch (error) {
      console.error("Fout bij het sluiten van de orders:", error)
    } finally {
      setLoading(false)
    }
  }

  const handlePageRefresh = () => {
    window.location.reload()
  }

  const handleExportDialogOpen = () => {
    setExportDialogOpen(true)
  }

  const handleImportDialogOpen = () => {
    setImportDialogOpen(true)
  }

  const handlePartinfoDialogOpen = () => {
    setPartinfoDialogOpen(true)
  }

  const handleReportDialogOpen = () => {
    setReportDialogOpen(true)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" component="div">
            Dashboard
          </Typography>
          <IconButton color="inherit" onClick={handlePageRefresh}>
            <RefreshIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => setAllRowsExpanded((prevState) => !prevState)}>
            <UnfoldMoreIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => setShowBriefpost((prevState) => !prevState)}>
            {showBriefpost ? <EmailIcon /> : <EmailOutlinedIcon />}
          </IconButton>
          <IconButton color="inherit" onClick={() => setShowThuisbezorgen((prevState) => !prevState)}>
            {showThuisbezorgen ? <LocalShippingIcon /> : <LocalShippingOutlinedIcon />}
          </IconButton>
          <IconButton color="inherit" onClick={handleExportDialogOpen}>
            {isMobile ? null : <CloudDownloadIcon />}
          </IconButton>
          <IconButton color="inherit" onClick={handleImportDialogOpen}>
            {isMobile ? null : <CloudUploadIcon />}
          </IconButton>
          <IconButton color="inherit" onClick={handlePartinfoDialogOpen}>
            {isMobile ? null : <TipsAndUpdatesIcon />}
          </IconButton>
          <IconButton color="inherit" onClick={handleReportDialogOpen}>
            {isMobile ? null : <EuroIcon />}
          </IconButton>
          <TextField
            variant="filled"
            size="small"
            label="Zoeken..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              style: {
                color: "white",
                backgroundColor: "#1976D2",
              },
              endAdornment: (
                <IconButton onClick={() => setSearchTerm("")} edge="end" size="small">
                  <ClearIcon style={{ color: "white", fontSize: 16 }} />
                </IconButton>
              ),
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={100} style={{ color: "#059be7" }} />
          </div>
        ) : (
          <div>
            {orders && orders.length > 0 && (
              <Container>
                <Typography variant="h6" component="div">
                  Open orders: {orders && orders.length > 0 && `${orders.length}`}
                </Typography>
                <TableContainer component={Card}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nummer</TableCell>
                        <TableCell>Prijs</TableCell>
                        <TableCell>Naam</TableCell>
                        <TableCell className="date-cell">Datum</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders
                        .filter((order) => {
                          const searchTermLower = searchTerm.toLowerCase()

                          const customerMatch =
                            order.shipping.first_name.toLowerCase().includes(searchTermLower) ||
                            order.shipping.last_name.toLowerCase().includes(searchTermLower)

                          const lineItemsMatch = order.line_items.some(
                            (item) => item.name.toLowerCase().includes(searchTermLower) || item.sku.toLowerCase().includes(searchTermLower)
                          )

                          const orderMatch = customerMatch || lineItemsMatch

                          return (
                            orderMatch &&
                            ((showBriefpost && order.shipping_lines.some((line) => line.method_title.includes("Briefpost"))) ||
                              (showThuisbezorgen && !order.shipping_lines.some((line) => line.method_title.includes("Briefpost"))) ||
                              (!showBriefpost && !showThuisbezorgen))
                          )
                        })
                        .map((order) => (
                          <Order key={order.id} order={order} handleDone={handleDone} allRowsExpanded={allRowsExpanded} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            )}
            {!orders?.length && (
              <div className={classes.loadingContainer}>
                <Typography>Geen orders gevonden...</Typography>
              </div>
            )}
          </div>
        )}
        <ExportDialog
          orders={orders}
          open={exportDialogOpen}
          handleClose={() => setExportDialogOpen(false)}
          handleDoneMain={handleDoneMain}
        />
        <PartinfoDialog open={partinfoDialogOpen} handleClose={() => setPartinfoDialogOpen(false)} />
        <ImportDialog open={importDialogOpen} handleClose={() => setImportDialogOpen(false)} />
        <ReportDialog open={reportDialogOpen} handleClose={() => setReportDialogOpen(false)} />
      </div>
    </div>
  )
}

export default App
