import React, { useState } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"

function getCookieValue(cookieName) {
  const cookies = document.cookie.split("; ")
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=")
    if (name === cookieName) {
      return value
    }
  }
  return null
}

const cookieName = "playmodok"

const AppContainer = () => {
  const [cookieValue, setCookieValue] = useState(getCookieValue(cookieName))
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const createCookie = () => {
    const expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
    const expires = `expires=${expirationDate.toUTCString()}`

    document.cookie = `${cookieName}=${inputValue}; ${expires}`
    setCookieValue(inputValue)
  }

  return (
    <Router>
      {cookieValue === "12345" ? (
        <App />
      ) : (
        <div>
          {cookieValue ? (
            <div>Niet geautoriseerd! Vraag systeembeheerder om hulp.</div>
          ) : (
            <div>
              <input type="text" value={inputValue} onChange={handleInputChange} placeholder="Vul de code in" />
              <button onClick={createCookie}>Ga door</button>
            </div>
          )}
        </div>
      )}
    </Router>
  )
}

ReactDOM.render(<AppContainer />, document.getElementById("root"))
