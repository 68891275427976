import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
} from "@mui/material"
import { CloudDownload as CloudDownloadIcon, DoneAll as DoneAllIcon } from "@mui/icons-material"
import * as XLSX from "xlsx"

function ExportDialog({ orders, open, handleClose, handleDoneMain }) {
  const [selectedOrders, setSelectedOrders] = useState([])

  const handleDoneAll = () => {
    handleDoneMain(selectedOrders)
  }

  const handleCloseDialog = () => {
    handleClose()
  }

  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelectedOrders) => {
      if (prevSelectedOrders.includes(orderId)) {
        return prevSelectedOrders.filter((id) => id !== orderId)
      } else {
        return [...prevSelectedOrders, orderId]
      }
    })
  }

  const exportToExcel = () => {
    if (orders && orders.length > 0) {
      const selectedData = orders
        .filter((order) => selectedOrders.includes(order.id))
        .map((order) => {
          const fullName = `${order.shipping.first_name} ${order.shipping.last_name}`

          const adresString = `${order.shipping.address_1}`
          const adresRegex = /^(\D+)\s+(\d+)(\D*)$/

          const matches = adresString.match(adresRegex)
          const straat = matches ? matches[1] : ""
          const huisnummer = matches ? matches[2] : ""
          const toevoeging = matches ? matches[3] : ""

          const gewicht = order.shipping_lines.reduce((acc, line) => {
            if (line.total < 4) {
              return 0
            } else if (line.total > 4 && line.total < 5) {
              return 15
            } else {
              return 2
            }
          }, 0)

          if (order.shipping.country === "NL") {
            return [
              fullName,
              "",
              order.billing.email,
              order.billing.phone,
              "",
              huisnummer,
              toevoeging,
              order.shipping.postcode,
              "",
              order.shipping.country,
              gewicht,
              "",
              "nee",
            ]
          } else {
            return [
              fullName,
              "",
              order.billing.email,
              "",
              straat,
              huisnummer,
              toevoeging,
              order.shipping.postcode,
              order.shipping.city,
              order.shipping.country,
              gewicht,
              "",
              "nee",
            ]
          }
        })

      const headers = [
        "Naam ontvanger",
        "Bedrijfsnaam (optioneel)",
        "E-mailadres",
        "Telefoonnummer (optioneel)",
        "Straatnaam ontvanger",
        "Huisnummer ontvanger",
        "Huisnummertoevoeging ontvanger",
        "Postcode ontvanger",
        "Plaats ontvanger",
        "Landcode",
        "Gewicht in hele kilogram (0 indien parcelletter)",
        "Extra adresinfo",
        "Verhoogde aansprakelijkheid (ja of nee)",
      ]

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...selectedData])
      XLSX.utils.book_append_sheet(workbook, worksheet, "Orders")

      XLSX.writeFile(workbook, "DPD_Labels.xlsx")
    }
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
      <DialogTitle>
        Exporteer DPD Labels
        <IconButton color="primary" onClick={exportToExcel}>
          <CloudDownloadIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            handleDoneAll()
            handleCloseDialog()
          }}
        >
          <DoneAllIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Export</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Naam</TableCell>
                <TableCell>Verzending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.length > 0 ? (
                orders
                  .filter((order) => !order.shipping_lines.some((line) => line.method_title.includes("Briefpost")))
                  .map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        <Checkbox checked={selectedOrders.includes(order.id)} onChange={() => handleCheckboxChange(order.id)} />
                      </TableCell>
                      <TableCell>{`${order.id}`}</TableCell>
                      <TableCell>{`${order.shipping.first_name} ${order.shipping.last_name}`}</TableCell>
                      <TableCell>{`${order.shipping_lines[0].method_title} - €${order.shipping_total}`}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>Geen orders gevonden</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

export default ExportDialog
