import React, { useState, Fragment } from "react"
import {
  ButtonGroup,
  Typography,
  TableRow,
  TableCell,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Table,
  TableHead,
  TableBody,
  Collapse,
  Box,
  IconButton,
  useMediaQuery,
  Dialog,
  DialogContent,
} from "@mui/material"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import EditIcon from "@mui/icons-material/Edit"
import ClearIcon from "@mui/icons-material/Clear"
import DescriptionIcon from "@mui/icons-material/Description"
import Flag from "react-world-flags"
import { NavLink } from "react-router-dom"
import "../App.css"

const Order = (props) => {
  const { order, allRowsExpanded } = props
  const [expanded, setExpanded] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const userAgent = navigator.userAgent
  const deviceName = userAgent.split("(")[1].split(";")[0].trim()

  const isMobile = useMediaQuery("(max-width: 600px)")

  const regexSet = /^(\d{4,5})-.*/
  const regexPart = /(?:^|\D)(30\d{6})(?:\D|$)/

  const handleClickOpen = (imageSrc) => {
    setSelectedImage(imageSrc)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setSelectedImage(null)
    setOpenDialog(false)
  }

  function calculateTimeSince(dateString) {
    const date = new Date(dateString)
    const currentDate = new Date()

    const timeDifference = currentDate - date

    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    if (hours > 0) {
      return `${hours} u`
    } else if (minutes > 0) {
      return `${minutes} m`
    }
  }

  const handleDocsClick = async (sku) => {
    const matchSet = sku.match(regexSet)
    const matchPart = sku.match(regexPart)

    if (matchSet) {
      const fourDigits = matchSet[1]
      const instructionURL = `https://playmobil.a.bigcontent.io/v1/static/${fourDigits}_buildinginstruction?locale=nl-NL,nl,*`

      try {
        const response = await fetch(instructionURL)

        if (response.ok) {
          window.open(instructionURL, "_blank")
        } else {
          window.open(`https://www.google.nl/search?q=playmobil+${fourDigits}+handleiding`, "_blank")
        }
      } catch (error) {
        console.error("Fout bij het ophalen van de handleiding:", error)
      }
    } else if (matchPart) {
      const eightDigits = matchPart[1]
      window.open(`https://www.google.nl/search?q=playmobil+${eightDigits}`, "_blank")
    }
  }

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: order.backgroundColor }} onClick={() => setExpanded(!expanded)}>
        <TableCell style={{ width: "10%" }}>{order.id}</TableCell>
        <TableCell style={{ width: "10%" }}>€{order.total}</TableCell>
        <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", width: "57%" }}>
          {order.shipping.country !== "NL" && <Flag code={order.shipping.country} height={10} width={10} />} {order.shipping.first_name}{" "}
          {order.shipping.last_name}
        </TableCell>
        {deviceName.includes("Windows") ? (
          <TableCell className="date-cell">
            {new Date(order.date_created).toLocaleDateString("nl-NL", {
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </TableCell>
        ) : (
          <TableCell className="date-cell">{calculateTimeSince(order.date_created)}</TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded || allRowsExpanded} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Card raised>
                <CardHeader
                  title={
                    <div>
                      <Typography>
                        Totaalbedrag €{order.total} ( {order.payment_method_title} )
                      </Typography>
                      {order.shipping_lines.length > 0 ? (
                        <Typography>
                          Verzendkosten: €{order.shipping_lines[0].total} ( {order.shipping_lines[0].method_title} )
                        </Typography>
                      ) : (
                        <Typography>Verzendkosten niet van toepassing</Typography>
                      )}
                    </div>
                  }
                  subheader={
                    <div>
                      <Typography
                        onClick={() => {
                          navigator.clipboard.writeText(order.shipping.first_name + " " + order.shipping.last_name)
                        }}
                      >
                        {order.shipping.first_name} {order.shipping.last_name}
                      </Typography>
                      <Typography>{order.shipping.address_1}</Typography>
                      <Typography
                        onClick={() => {
                          navigator.clipboard.writeText(order.shipping.postcode)
                        }}
                      >
                        {order.shipping.postcode} {order.shipping.city}
                      </Typography>
                      <Typography
                        onClick={() => {
                          navigator.clipboard.writeText(order.billing.email)
                        }}
                      >
                        {order.billing.email}
                      </Typography>
                      <Typography onClick={() => (window.location.href = `tel:${order.billing.phone}`)}>{order.billing.phone}</Typography>
                      <IconButton size="medium" onClick={() => props.handleDone(order.id)}>
                        <DoneAllIcon />
                      </IconButton>
                      <IconButton
                        size="medium"
                        onClick={() => window.open(`https://www.playmodok.nl/wp-admin/post.php?post=${order.id}&action=edit`)}
                      >
                        <EditIcon />
                      </IconButton>
                      {order.customer_note.length > 0 ? (
                        <Typography style={{ fontStyle: "italic", whiteSpace: "pre-line" }}>{order.customer_note}</Typography>
                      ) : null}
                    </div>
                  }
                />
                <CardContent>
                  {isMobile ? (
                    <Fragment>
                      {order.line_items.map((regel) => (
                        <Card key={regel.id} style={{ marginBottom: 5 }}>
                          <CardHeader
                            action={
                              <Typography style={{ fontSize: "12px", textAlign: "right", fontWeight: "bold" }}>
                                {regel.quantity}x
                              </Typography>
                            }
                            title={<Typography style={{ fontSize: "12px", fontWeight: "bold" }}>{regel.sku}</Typography>}
                          />
                          <CardMedia sx={{ width: 200, margin: "auto" }} component="img" image={regel.image.src} />
                          <CardContent>
                            <Typography style={{ fontSize: "12px" }}>{regel.name}</Typography>
                          </CardContent>
                        </Card>
                      ))}
                    </Fragment>
                  ) : (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Afbeelding</TableCell>
                          <TableCell>SKU</TableCell>
                          <TableCell>Naam</TableCell>
                          <TableCell>Aantal</TableCell>
                          <TableCell>Prijs</TableCell>
                          <TableCell>Acties</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.line_items.map((regel) => (
                          <TableRow key={regel.id}>
                            <TableCell>
                              <img src={regel.image.src} height="50" alt="Afbeelding" onClick={() => handleClickOpen(regel.image.src)} />
                            </TableCell>
                            <Dialog open={openDialog} onClose={handleClose}>
                              <DialogContent>
                                {selectedImage && <img src={selectedImage} alt="Afbeelding" style={{ width: "100%", height: "auto" }} />}
                              </DialogContent>
                            </Dialog>
                            <TableCell>{regel.sku}</TableCell>
                            <TableCell>{regel.name}</TableCell>
                            <TableCell>{regel.quantity}</TableCell>
                            <TableCell>€{regel.total}</TableCell>
                            <TableCell>
                              {regel.sku !== undefined ? (
                                <ButtonGroup>
                                  {regexSet.test(regel.sku) || regexPart.test(regel.sku) ? (
                                    <IconButton size="small" onClick={() => handleDocsClick(regel.sku)}>
                                      <DescriptionIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton size="small">
                                      <ClearIcon />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    size="small"
                                    component={NavLink}
                                    to={`https://www.playmodok.nl/wp-admin/post.php?post=${regel.product_id}&action=edit`}
                                    target="_blank"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </ButtonGroup>
                              ) : (
                                <Typography>Geen SKU</Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default Order
