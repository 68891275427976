import React, { useState } from "react"
import axios from "axios"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload"

function ImportDialog({ open, handleClose }) {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [partNumbers, setPartNumbers] = useState("")
  const [prefix, setPrefix] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [tableData, setTableData] = useState([])
  const [showTable, setShowTable] = useState(false)

  const handlePartNumbersChange = (event) => {
    setPartNumbers(event.target.value)
  }

  const handlePrefixChange = (event) => {
    setPrefix(event.target.value)
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value)
  }

  const handleInputChange = (event, index, field) => {
    const newTableData = [...tableData]
    newTableData[index][field] = event.target.value
    setTableData(newTableData)
  }

  const handleShowTable = async () => {
    setShowTable(true)
    setLoading(true)

    const partNumbersArray = partNumbers
      .split(";")
      .map((pn) => pn.trim())
      .filter((pn) => pn.length > 0)

    try {
      const priceResponse = await fetch("http://localhost:3001/api/playmobil/parts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ partNumbers: partNumbersArray }),
      })
      const priceData = await priceResponse.json()

      const detailsResponse = await fetch("http://localhost:3001/api/parts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ partNumbers: partNumbersArray }),
      })
      const detailsData = await detailsResponse.json()

      // Combine data
      const combinedData = partNumbersArray.map((partNumber) => {
        const priceInfo = priceData.find((item) => item.partNumber === partNumber)
        const detailInfo = detailsData.find((item) => item.partnummer === partNumber)
        return {
          partNumber,
          partnaam: detailInfo ? detailInfo.partnaam : "",
          price: priceInfo ? priceInfo.price.replace(" €", "") : "",
          image: detailInfo ? detailInfo.afbeelding : "",
          color: detailInfo ? detailInfo.kleur : "",
          sets: detailInfo ? detailInfo.sets : "",
        }
      })

      setTableData(combinedData)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const fetchCategories = async () => {
    try {
      setLoading(true)
      const [categoriePage1, categoriePage2, categoriePage3] = await Promise.all([
        axios.get("https://www.playmodok.nl/wp-json/wc/v3/products/categories?&page=1&per_page=100", {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }),
        axios.get("https://www.playmodok.nl/wp-json/wc/v3/products/categories?&page=2&per_page=100", {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }),
        axios.get("https://www.playmodok.nl/wp-json/wc/v3/products/categories?&page=3&per_page=100", {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }),
      ])
      const combinedCategories = [...categoriePage1.data, ...categoriePage2.data, ...categoriePage3.data]
      setCategories(combinedCategories)
    } catch (error) {
      console.error("Error fetching categories:", error)
    } finally {
      setLoading(false)
    }
  }

  const importProducts = async () => {
    try {
      setLoading(true)

      console.log(tableData)

      for (let part of tableData) {
        const { partNumber, partnaam, price, sets, color, image } = part

        const prefixedPartNumber = `${prefix}_${partNumber}`
        const description = `Playmobil set: ${sets.join(", ")}\n\nKleur: ${color}`

        const data = {
          sku: prefixedPartNumber,
          name: partnaam || `Part ${partNumber}`,
          type: "simple",
          status: "private",
          manage_stock: true,
          stock_quantity: 1,
          weight: "10",
          regular_price: price ? price.replace(" €", "") : "99.99",
          description: description,
          categories: [
            {
              id: selectedCategory,
            },
          ],
          images: [
            {
              src: image,
            },
          ],
        }

        console.log(data)

        // await axios.post("https://www.playmodok.nl/wp-json/wc/v3/products", data, {
        //   auth: {
        //     username: process.env.REACT_APP_USERNAME,
        //     password: process.env.REACT_APP_PASSWORD,
        //   },
        // })

        console.log(`Product ${partNumber} succesvol geüpload.`)
      }
    } catch (error) {
      console.error("Error tijdens het uploaden van producten:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Importeer producten
        <IconButton color="primary" onClick={fetchCategories} sx={{ marginLeft: 2 }}>
          <RefreshIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleShowTable} sx={{ marginLeft: 2 }}>
          <ManageSearchIcon />
        </IconButton>
        <IconButton color="primary" onClick={importProducts} sx={{ marginLeft: 2 }}>
          <DriveFolderUploadIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress size={100} style={{ color: "#059be7" }} />
        ) : (
          <div>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <FormControl fullWidth sx={{ marginTop: 1 }}>
                <InputLabel id="category-label">Categorie</InputLabel>
                <Select labelId="category-label" value={selectedCategory} onChange={handleCategoryChange} label="Categorie">
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ marginTop: 1 }}>
                <TextField label="Prefix" variant="outlined" value={prefix} onChange={handlePrefixChange} placeholder="Prefix" />
              </FormControl>
            </Box>
            <TextField
              label="Onderdeelnummers"
              variant="outlined"
              fullWidth
              value={partNumbers}
              onChange={handlePartNumbersChange}
              placeholder="Voer onderdeelnummers in, gescheiden door ;"
              sx={{ marginTop: 2 }}
            />
            {showTable && (
              <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Afbeelding</TableCell>
                      <TableCell>Onderdeelnummer</TableCell>
                      <TableCell>Naam</TableCell>
                      <TableCell>Prijs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <img
                            src={row.image || "https://media.playmobil.com/i/playmobil/placeholder_spareparts"}
                            alt={`Afbeelding voor ${row.partNumber}`}
                            style={{ width: 50, height: 50, objectFit: "contain" }}
                          />
                        </TableCell>
                        <TableCell>{prefix ? `${prefix}_${row.partNumber}` : row.partNumber}</TableCell>
                        <TableCell>
                          <TextField
                            value={row.partnaam}
                            onChange={(e) => handleInputChange(e, index, "partnaam")}
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={row.price ? parseFloat(row.price.replace(",", ".")).toFixed(2) : ""}
                            onChange={(e) => handleInputChange(e, index, "price")}
                            variant="outlined"
                            size="small"
                            inputProps={{
                              step: 0.01,
                              min: 0,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ImportDialog
