import React, { useState, useEffect } from "react"
import axios from "axios"
import dayjs from "dayjs"
import {
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
} from "@mui/material"

const getMonthsFromJanuary2024 = () => {
  const start = dayjs("2024-01-01")
  const months = []
  let current = dayjs()

  while (current.isAfter(start) || current.isSame(start)) {
    months.push(current.format("YYYY-MM"))
    current = current.subtract(1, "month")
  }

  return months
}

function ReportDialog({ open, handleClose }) {
  const [loading, setLoading] = useState(false)
  const [reportData, setReportData] = useState([])
  const [shippingCost, setShippingCost] = useState(null)
  const [orders, setOrders] = useState([])
  const [loadingShippingCost, setLoadingShippingCost] = useState(false)

  const currentDate = new Date()
  const defaultMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}`
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth)

  const availableMonths = getMonthsFromJanuary2024()
  const dailyTotals = reportData?.[0]?.totals || {}
  const summaryData = reportData?.[0] || {}

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const [year, month] = selectedMonth.split("-")
        const dateMin = dayjs(`${year}-${month}-01`).format("YYYY-MM-DD")
        const dateMax = dayjs(`${year}-${month}-01`).endOf("month").format("YYYY-MM-DD")

        const reportResponse = await axios.get(
          `https://playmodok.nl/wp-json/wc/v3/reports/sales?&date_min=${dateMin}&date_max=${dateMax}`,
          {
            auth: {
              username: process.env.REACT_APP_USERNAME,
              password: process.env.REACT_APP_PASSWORD,
            },
          }
        )

        setReportData(reportResponse.data)
      } catch (error) {
        console.error("Error:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [selectedMonth])

  useEffect(() => {
    const fetchShippingCost = async () => {
      try {
        setLoadingShippingCost(true)
        const response = await axios.get(`https://api.playmodok.nl/api/shipping-cost?selectedMonth=${selectedMonth}`)

        // Sla zowel de totale verzendkosten als de orderdetails op in de state
        setShippingCost({
          totalCostIncl: response.data.totalCostIncl,
          orderDetails: response.data.orderDetails,
        })
      } catch (error) {
        console.error("Error fetching shipping cost:", error)
      } finally {
        setLoadingShippingCost(false)
      }
    }

    fetchShippingCost()
  }, [selectedMonth])

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const [year, month] = selectedMonth.split("-")
        const dateMin = `${year}-${month}-01T00:00:00` // Eerste dag van de geselecteerde maand
        const dateMax = dayjs(`${year}-${month}-01`).add(1, "month").format("YYYY-MM-DDT00:00:00") // Eerste dag van de volgende maand
        let allOrders = []
        let page = 1
        let hasMoreOrders = true

        while (hasMoreOrders) {
          const ordersResponse = await axios.get(
            `https://playmodok.nl/wp-json/wc/v3/orders?per_page=100&page=${page}&after=${dateMin}&before=${dateMax}&status=completed`,
            {
              auth: {
                username: process.env.REACT_APP_USERNAME,
                password: process.env.REACT_APP_PASSWORD,
              },
            }
          )

          const ordersData = ordersResponse.data.map((order) => ({
            id: order.id,
            shipping_total: order.shipping_total,
          }))

          allOrders = allOrders.concat(ordersData)

          if (ordersResponse.data.length < 100) {
            hasMoreOrders = false // No more orders to fetch
          } else {
            page += 1 // Move to the next page
          }
        }

        setOrders(allOrders)
      } catch (error) {
        console.error("Error fetching orders:", error)
      }
    }

    fetchOrders()
  }, [selectedMonth])

  console.log(orders)
  console.log(shippingCost)

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Verkoop rapport</Typography>
          <Select value={selectedMonth} onChange={handleMonthChange} variant="outlined" size="small">
            {availableMonths.map((month) => (
              <MenuItem key={month} value={month}>
                {dayjs(month).format("MMMM YYYY")}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress size={100} style={{ color: "#059be7" }} />
        ) : (
          <Box>
            <Card sx={{ mb: 3, p: 2, backgroundColor: "#f7f9fc" }}>
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  Samenvatting
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography>Inkomsten uit bestellingen:</Typography>
                    <Typography>Betaalde verzendkosten:</Typography>
                    <Typography>Werkelijke verzendkosten:</Typography>
                    <Typography>Belastingen:</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography>Winst:</Typography>
                  </Box>

                  <Box textAlign="right">
                    <Typography>€{summaryData.total_sales}</Typography>
                    <Typography>€{summaryData.total_shipping}</Typography>
                    {loadingShippingCost ? (
                      <CircularProgress size={20} style={{ color: "#059be7" }} />
                    ) : (
                      <Typography>€{shippingCost?.totalCostIncl}</Typography>
                    )}
                    <Typography>€{summaryData.total_tax}</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography>€{summaryData.net_sales}</Typography>
                  </Box>

                  <Box textAlign="right" ml={4}>
                    <Typography>Bestellingen: {summaryData.total_orders}</Typography>
                    <Typography>Klanten: {summaryData.total_customers}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <TableContainer component={Card} sx={{ mb: 2 }} elevation={6}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell align="right">Omzet (€)</TableCell>
                    <TableCell align="right">Bestellingen</TableCell>
                    <TableCell align="right">Verzending (€)</TableCell>
                    <TableCell align="right">Korting (€)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(dailyTotals).map(([date, details]) => (
                    <TableRow key={date}>
                      <TableCell>{date}</TableCell>
                      <TableCell align="right">{details.sales}</TableCell>
                      <TableCell align="right">{details.orders}</TableCell>
                      <TableCell align="right">{details.shipping}</TableCell>
                      <TableCell align="right">{details.discount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Card} sx={{ mb: 2 }} elevation={6}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ordernummer</TableCell>
                    <TableCell align="right">Verzendkosten (€)</TableCell>
                    <TableCell align="right">Werkelijke prijs (€)</TableCell>
                    <TableCell align="right">Verschil (€)</TableCell> {/* Nieuwe kolom */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => {
                    // Zoek naar de orderdetail die overeenkomt met het ordernummer van deze order
                    const orderDetail = shippingCost?.orderDetails?.find((detail) => String(detail.order_number) === String(order.id))

                    // Bereken het verschil tussen verzendkosten en werkelijke prijs
                    const difference = orderDetail
                      ? (parseFloat(order.shipping_total) - parseFloat(orderDetail.lastprice)).toFixed(2)
                      : "0.00"

                    // Kleur de regel rood als lastprice groter is dan shipping_total
                    const rowStyle =
                      orderDetail && parseFloat(orderDetail.lastprice) > parseFloat(order.shipping_total)
                        ? { backgroundColor: "#ffcccc" } // Rood kleuren als lastprice > shipping_total
                        : {}

                    return (
                      <TableRow key={order.id} style={rowStyle}>
                        {" "}
                        {/* Rood kleuren als lastprice groter is dan shipping_total */}
                        <TableCell>{order.id}</TableCell>
                        <TableCell align="right">€{order.shipping_total}</TableCell>
                        <TableCell align="right">{orderDetail ? "€" + orderDetail.lastprice : "Onbekend"}</TableCell>
                        <TableCell align="right">€{difference}</TableCell> {/* Verschil weergeven */}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ReportDialog
